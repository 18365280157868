import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { ChangeEvent } from 'react';

interface Props {
  isChecked: boolean;
  onChange: (canReceiveSms: boolean) => void;
}

export default function SmsCheckbox({
  isChecked,
  onChange,
}: Props): JSX.Element {
  function handleChange({ currentTarget }: ChangeEvent<HTMLInputElement>) {
    onChange(Boolean(currentTarget.checked));
  }

  return (
    <FormControl>
      <FormLabel fontSize="sm" mb={1}>
        SMS Notifications
      </FormLabel>
      <Checkbox
        colorScheme="green"
        isChecked={isChecked}
        onChange={handleChange}
        size="lg"
      >
        <Text fontSize="sm">
          Opt-in to SMS updates on your membership and special offers.
        </Text>
      </Checkbox>

      <FormHelperText fontSize="2xs">
        By opting-in to receive SMS messages you consent to receive account
        notifications and marketing-related text messages. Consent is not a
        condition of purchase. Message and data rates may apply. Message
        frequency varies. Unsubscribe at any time by replying STOP or clicking
        the unsubscribe link (where available).
      </FormHelperText>
    </FormControl>
  );
}
