import { memo } from 'react';
import { Text, Link } from '@chakra-ui/react';

interface Props {
  terms?: string | null;
}

function Terms({ terms }: Props): JSX.Element {
  return (
    <Text fontSize="xs" color="gray.600">
      By clicking "Sign Up" you agree to our{' '}
      <Link href="https://www.mugclub.beer/terms-of-service" isExternal>
        terms of service
      </Link>{' '}
      and{' '}
      <Link href="https://www.mugclub.beer/privacy-policy" isExternal>
        privacy policy
      </Link>
      .
      {terms &&
        ` You also agree to the following terms set by the brewery: ${terms}`}
    </Text>
  );
}

export default memo(Terms);
