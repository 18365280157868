import { memo } from 'react';
import { Box, Text } from '@chakra-ui/react';

interface Props {
  children?: React.ReactNode;
  label?: string;
  value?: string;
}

function Detail({ children, label, value }: Props): JSX.Element {
  return (
    <Box alignSelf="stretch">
      {label && (
        <Text fontWeight="semibold" mb={0} fontSize="2xs" color="gray.600">
          {label}
        </Text>
      )}
      {value && <Text fontSize="sm">{value}</Text>}
      {children}
    </Box>
  );
}

export default memo(Detail);
