import { memo } from 'react';
import { Tooltip } from '@chakra-ui/react';
import { CheckCircle } from '../../../assets';

function SmsOptIn(): JSX.Element {
  return (
    <Tooltip
      label="This patron is opted-in to SMS messages."
      placement="bottom-end"
    >
      <span>
        <CheckCircle color="green.500" />
      </span>
    </Tooltip>
  );
}

export default memo(SmsOptIn);
