import { useEffect, useState } from 'react';
import { useFetchClub, useFetchPatronsByClub } from './';
import { Mug } from '../types';

type ReturnValue = [Mug[], boolean];

export default function useGetAvailableMugsByClub(
  clubId?: string,
  filterOccupied?: boolean,
): ReturnValue {
  const [club] = useFetchClub(clubId);
  const [patrons] = useFetchPatronsByClub(clubId);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mugs, setMugs] = useState<Mug[]>([]);

  useEffect(() => {
    if (!clubId) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [clubId]);

  useEffect(() => {
    if (!club) {
      return;
    }

    const { capacity } = club;

    if (!capacity) {
      setIsLoading(false);
      return;
    }

    const mugs: Mug[] = [];

    for (let i = 1; i <= capacity; i++) {
      const patron = patrons.find(
        ({ mugNumber }) => parseMugNumber(mugNumber) === i,
      );

      if (filterOccupied && patron) {
        continue;
      }

      mugs.push({
        clubId: club.id,
        number: i,
        patron,
      });
    }

    setMugs(mugs);
    setIsLoading(false);
  }, [club, filterOccupied, isLoading, patrons]);

  return [mugs, isLoading];
}

function parseMugNumber(value?: number | null): number | undefined {
  if (!value) {
    return;
  }

  if (typeof value === 'number') {
    return value;
  }

  return parseInt(value);
}
