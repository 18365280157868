import { Box, Heading } from '@chakra-ui/react';
import SignUp from './SignUp';
import { IClub } from '../../../types';

interface Props {
  club: IClub;
}

export default function PatronSignupForm({ club }: Props): JSX.Element {
  return (
    <Box>
      <Heading as="h2" fontSize="lg" mb={4}>
        Patron Signup Form
      </Heading>

      {club && <SignUp club={club} />}
    </Box>
  );
}
