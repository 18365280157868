import { memo } from 'react';
import {
  FormHelperText,
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { CheckIcon } from '../../../assets';
import { useGetAvailableMugsByClub } from '../../../hooks';

interface Props {
  clubId: string;
  onChange: (value: string | null) => void;
  value?: string | null;
}

function MugNumberSelect({ clubId, onChange, value }: Props): JSX.Element {
  const [mugs, isLoading] = useGetAvailableMugsByClub(clubId, true);

  function handleClick(value: number) {
    onChange(value.toString());
  }

  function handleClear() {
    onChange(null);
  }

  return (
    <FormControl>
      <FormLabel htmlFor="mugNumber" fontSize="sm" mb={1}>
        Mug Number
      </FormLabel>
      <FormHelperText mt={0} mb={2}>
        Select a mug number from a list of available mugs.
      </FormHelperText>

      {isLoading && <Box>Loading Available Mugs...</Box>}

      {value && (
        <Box
          alignItems="center"
          display="flex"
          border="2px solid"
          borderColor="gray.500"
          bg="gray.50"
          mb={4}
          p={2}
          px={4}
          fontSize="sm"
          justifyContent="space-between"
          rounded="md"
        >
          <Box display="flex" gap={1} fontWeight="medium">
            <Text>Selected Mug Number:</Text> {value}
          </Box>

          <Button size="xs" variant="ghost" color="gray" onClick={handleClear}>
            Clear Selection
          </Button>
        </Box>
      )}

      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={1}>
        {mugs.map(({ number }) => (
          <MugButton
            isSelected={value === number.toString()}
            number={number}
            onClick={handleClick}
          />
        ))}
      </Box>
    </FormControl>
  );
}

interface MugButtonProps {
  isSelected: boolean;
  number: number;
  onClick: (mugNumber: number) => void;
}

function MugButton({
  isSelected,
  number,
  onClick,
}: MugButtonProps): JSX.Element {
  return (
    <Button
      borderColor={isSelected ? 'green.500' : 'gray.300'}
      color={isSelected ? 'white' : 'black'}
      display="flex"
      gap={1}
      onClick={() => onClick(number)}
      variant={isSelected ? 'solid' : 'outline'}
      size="sm"
    >
      {isSelected && <CheckIcon />}
      {number}
    </Button>
  );
}

export default memo(MugNumberSelect);
