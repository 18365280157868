import { FormControl, Text, Textarea } from '@chakra-ui/react';
import { IClub } from '../../types';

interface Props {
  club: IClub;
  isEditable?: boolean;
  onChange: (key: keyof IClub, value: string | number) => void;
}

export default function CustomQuestions({
  club,
  isEditable,
  onChange,
}: Props): JSX.Element {
  const { customQuestions } = club;

  return (
    <>
      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        Custom Questions
      </Text>

      <FormControl mb={3}>
        <Textarea
          placeholder="Custom questions"
          value={customQuestions || ''}
          readOnly={!isEditable}
          onChange={({ currentTarget }) =>
            onChange('customQuestions', currentTarget.value)
          }
        />
      </FormControl>
    </>
  );
}
