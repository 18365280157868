import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { formatPhoneNumber, unformatPhoneNumber } from '../../utils';
import { IBrewery } from '../../types';

type FormValues = {
  name: string;
  website: string;
  phoneNumber: string;
};

interface Props {
  brewery?: IBrewery;
  onSubmit: (values: FormValues) => void;
  submitButtonText: string;
}

export default function BreweryForm({
  brewery,
  onSubmit,
  submitButtonText,
}: Props): JSX.Element {
  const [name, setName] = useState<string>(brewery?.name || '');
  const [website, setWebsite] = useState<string>(brewery?.website || '');
  const [phoneNumber, setPhoneNumber] = useState<string>(
    brewery?.phoneNumber || '',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isFormValid = name && website && phoneNumber;

  async function handleOnSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await onSubmit({
        name,
        website,
        phoneNumber: unformatPhoneNumber(phoneNumber),
      });
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <VStack spacing={4} mb={6}>
        <FormControl isRequired>
          <FormLabel htmlFor="name" fontSize="sm">
            Brewery Name
          </FormLabel>
          <Input
            id="name"
            type="text"
            placeholder="Name"
            defaultValue={name}
            onChange={({ target }) => setName(target.value)}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="website" fontSize="sm">
            Brewery Website
          </FormLabel>
          <Input
            id="website"
            type="text"
            placeholder="https://your-awesome-brewery.com"
            defaultValue={website}
            onChange={({ target }) => setWebsite(target.value)}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="phoneNumber" fontSize="sm">
            Phone Number
          </FormLabel>
          <Input
            id="phoneNumber"
            type="phone"
            placeholder="Name"
            defaultValue={formatPhoneNumber(phoneNumber)}
            onChange={({ target }) => setPhoneNumber(target.value)}
            value={formatPhoneNumber(phoneNumber)}
          />
        </FormControl>
      </VStack>

      <Button
        w="100%"
        type="submit"
        isLoading={isLoading}
        disabled={!isFormValid}
      >
        {submitButtonText}
      </Button>
    </form>
  );
}
