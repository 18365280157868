import { memo, useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { Box, Image, Heading, Skeleton } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { useFetchBrewery, useFetchClub } from '../../../hooks';
import { storage } from '../../../firebase';

interface Props {
  clubId?: string;
}

function ClubLogo({ clubId }: Props): JSX.Element {
  const [club] = useFetchClub(clubId);
  const [brewery] = useFetchBrewery(club?.breweryId);
  const [logoUrl, setLogoUrl] = useState<string>();

  useEffect(() => {
    async function fetchLogo() {
      if (!brewery?.id) {
        return;
      }

      try {
        const imageRef = ref(storage, `logos/${brewery.id}`);
        const url = await getDownloadURL(imageRef);

        setLogoUrl(url);
      } catch (error) {
        captureException(error);
      }
    }

    fetchLogo();
  }, [brewery]);

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        gap={2}
        py={2}
        px={4}
        mb={6}
        h={50}
        borderBottom="1px solid"
        borderColor="gray.100"
        position="sticky"
        top={0}
        bg="white"
        shadow="sm"
        zIndex={10}
      >
        <Skeleton
          isLoaded={Boolean(logoUrl)}
          h={logoUrl ? undefined : 8}
          w={logoUrl ? undefined : 8}
        >
          <Box boxShadow="md" rounded="md" p={1}>
            <Image src={logoUrl} maxH={8} rounded="md" />
          </Box>
        </Skeleton>

        <Skeleton
          isLoaded={Boolean(brewery)}
          h={4}
          w={brewery ? undefined : '150px'}
        >
          <Heading size="xs">{brewery?.name}</Heading>
        </Skeleton>
      </Box>

      <Box maxWidth={256} margin="auto">
        <Skeleton isLoaded={Boolean(logoUrl)} h={logoUrl ? undefined : 256}>
          <Box rounded="md" overflow="hidden" boxShadow="md" p={4}>
            <Image src={logoUrl} rounded="md" />
          </Box>
        </Skeleton>
      </Box>
    </>
  );
}

export default memo(ClubLogo);
