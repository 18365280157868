import { Box } from '@chakra-ui/react';
import { ReadOnlyMessageTextArea } from '../components';
import {
  BreweryConfigOptions,
  IBrewery,
  IBreweryConfig,
  ProductPlan,
} from '../../../types';

interface Props {
  brewery: IBrewery;
  breweryConfig: IBreweryConfig;
}

function formatRenewalReminder(brewery: IBrewery): string {
  const { name } = brewery;

  return `👋 Hello {{PATRON_FIRST_NAME}},\n
Congratulations! You earned a reward at ${name}:\n
{{REWARD_DESCRIPTION}}\n
Please stop by to redeem it.\n
Cheers,\n
The team at ${name}`;
}

export default function Rewards({
  brewery,
  breweryConfig,
}: Props): JSX.Element {
  const { patronEarnedRewardEmailEnabled } = breweryConfig;
  const { plan } = brewery;
  const hasFreePlan = plan === ProductPlan.FREE;

  return (
    <Box>
      <ReadOnlyMessageTextArea
        breweryId={brewery.id}
        configOption={BreweryConfigOptions.patronEarnedRewardEmailEnabled}
        defaultValue={formatRenewalReminder(brewery)}
        description="Email sent to patrons when they earn a new reward."
        isDefaultEnabled={patronEarnedRewardEmailEnabled}
        isFeatureGated={hasFreePlan}
        isToggleable
        label="Earned Reward"
        messageType="email"
        rows={12}
      />
    </Box>
  );
}
