import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { IPatron } from '../types';

type PatronData = Pick<
  IPatron,
  | 'birthdayMonth'
  | 'canReceiveSms'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'mugNumber'
  | 'phoneNumber'
  | 'shirtSize'
>;

interface IRequestData {
  clubId: string;
  notes?: string;
  patron: PatronData;
}

export interface IReturnData {
  message: string;
}

export const processPatronSignup = httpsCallable<IRequestData, IReturnData>(
  functions,
  'processPatronSignup',
);
