import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import { IMembership } from '../../types';

type CreateMembershipParams = Omit<IMembership, 'id'>;

export async function createMembership(
  params: CreateMembershipParams,
): Promise<IMembership> {
  const membershipsCollection = collection(db, 'memberships');

  const membershipDoc = await addDoc(membershipsCollection, params);

  return {
    ...params,
    id: membershipDoc.id,
  };
}
