import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import InfoMessage from './InfoMessage';
import SuccessMessage from './SuccessMessage';
import { fetchClubSignupPageData, IFetchClubSignupData } from '../../api';
import { FullscreenLoader } from '../../components';
import ClubOverview from './ClubOverview';
import ClubDetails from './ClubDetails';
import ClubLogo from './ClubLogo';
import PatronSignUpForm from './PatronSignUpForm';
import Footer from './Footer';
import { useGetBreweryConfig } from './utils';

export default function PatronSignUp(): JSX.Element {
  const { clubId } = useParams();
  const [pageData, setPageData] = useState<IFetchClubSignupData>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const breweryConfig = useGetBreweryConfig(pageData);

  useEffect(() => {
    async function fetchClubData() {
      if (!clubId) {
        return;
      }

      try {
        const { data } = await fetchClubSignupPageData({ clubId });
        setPageData(data);
      } catch (error) {
        captureException(error);
      }
    }

    fetchClubData();
  }, [clubId]);

  useEffect(() => {
    if (!pageData) {
      return;
    }

    const { brewery } = pageData;

    document.title = `${brewery.name}'s Mug Club Sign Up`;
  }, [pageData]);

  if (!pageData || !clubId) {
    return <FullscreenLoader />;
  }

  return (
    <Box>
      <ClubLogo clubId={clubId} />

      <Box maxWidth={512} margin="auto" px={4}>
        <ClubOverview pageData={pageData} />

        {isSuccess ? (
          <SuccessMessage message={breweryConfig.clubSignUpConfirmation} />
        ) : (
          <>
            <InfoMessage message={breweryConfig.clubSignUpInstructions} />

            <ClubDetails pageData={pageData} />

            <PatronSignUpForm
              clubId={clubId}
              onSuccess={() => setIsSuccess(true)}
              pageData={pageData}
            />
          </>
        )}
      </Box>

      <Footer />
    </Box>
  );
}
