import { memo } from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import { LogoPatch } from '../../../assets';

function Footer(): JSX.Element {
  return (
    <Box
      alignItems="center"
      borderTop="1px solid"
      borderColor="gray.200"
      mt={16}
      p={2}
      textAlign="center"
    >
      <Link
        alignItems="center"
        display="inline-flex"
        fontSize="xs"
        gap={2}
        justifyContent="center"
        href="https://mugclub.beer"
        isExternal
        fontWeight="semibold"
      >
        <Text>Powered by</Text>
        <LogoPatch />
      </Link>
    </Box>
  );
}

export default memo(Footer);
