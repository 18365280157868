import { Box, Heading, Text } from '@chakra-ui/react';
import { FormStepper } from './components';
import { LogoPatch } from '../../assets';

export default function GetStarted(): JSX.Element {
  return (
    <Box
      alignItems="center"
      bg="gray.100"
      display="flex"
      h="100vh"
      justifyContent="center"
      p={4}
    >
      <Box bg="white" rounded="lg" shadow="md" px={[4, 12]} py={8} w={768}>
        <Box mb={8} textAlign="center">
          <Box display="flex" justifyContent="center" mb={6}>
            <LogoPatch height="48" width="48" />
          </Box>

          <Heading as="h1" size={['md', 'lg']} mb={1}>
            Get Started for Free
          </Heading>
          <Text color="gray.500" fontSize="sm">
            Get started with MugClub.beer in minutes. No credit card required.
          </Text>
        </Box>

        <FormStepper />
      </Box>
    </Box>
  );
}
