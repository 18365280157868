import { memo, useEffect, useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { captureException } from '@sentry/react';
import RegistrationClosed from './RegistrationClosed';
import SmsCheckbox from './SmsCheckbox';
import Terms from './Terms';
import { registrationClosed } from './utils';
import { formatPhoneNumber, unformatPhoneNumber } from '../../../utils';
import { MugNumberGrid } from '../../../components';
import { processPatronSignup, IFetchClubSignupData } from '../../../api';

interface props {
  clubId: string;
  pageData: IFetchClubSignupData;
  onSuccess: () => void;
  terms?: string;
}

function PatronSignUpForm({ clubId, pageData, onSuccess }: props): JSX.Element {
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>();
  const [shirtSize, setShirtSize] = useState<string | null>(null);
  const [mugNumber, setMugNumber] = useState<string | null>(null);
  const [notes, setNotes] = useState<string | undefined>();
  const [canReceiveSms, setCanReceiveSms] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasBirthdayError, setHasBirthdayError] = useState<boolean>(false);

  const { club } = pageData;
  const {
    customQuestions,
    hasPatronMugNumberSelection,
    hasTShirts,
    registrationEndDate,
  } = club;

  const isRegistrationClosed = registrationClosed(registrationEndDate);
  const toast = useToast();

  useEffect(() => {
    if (!birthdate) {
      return;
    }

    const date = dayjs(birthdate);
    const age = dayjs().diff(date, 'year');

    if (age < 21) {
      setHasBirthdayError(true);
    } else {
      setHasBirthdayError(false);
    }
  }, [birthdate]);

  async function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();

    if (hasBirthdayError) {
      return;
    }

    setIsLoading(true);

    try {
      const patronData = {
        birthdayMonth: dayjs(birthdate).format('MMMM').toLowerCase(),
        canReceiveSms,
        email: email!,
        firstName: firstName!,
        lastName: lastName!,
        mugNumber: mugNumber ? parseInt(mugNumber) : null,
        phoneNumber: unformatPhoneNumber(phoneNumber!),
        shirtSize,
      };

      await processPatronSignup({
        clubId: clubId,
        notes,
        patron: patronData,
      });

      onSuccess();
    } catch (error) {
      captureException(error);

      setIsLoading(false);

      toast({
        description: 'An error occurred while signing up. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  if (isRegistrationClosed) {
    return <RegistrationClosed />;
  }

  return (
    <Box>
      <Heading as="h3" fontSize="md" fontWeight="semibold">
        Sign Up
      </Heading>
      <Text color="gray.500" fontSize="sm" mb={4}>
        Complete the following form to register.
      </Text>

      <form onSubmit={handleSubmit}>
        <VStack spacing={3} mb={8}>
          <Box display="flex" gap={2} w="full">
            <FormControl isRequired>
              <FormLabel fontSize="sm">First Name</FormLabel>
              <Input
                borderColor="gray.200"
                placeholder="First Name"
                onChange={({ currentTarget }) =>
                  setFirstName(currentTarget.value)
                }
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontSize="sm">Last Name</FormLabel>
              <Input
                borderColor="gray.200"
                placeholder="Last Name"
                onChange={({ currentTarget }) =>
                  setLastName(currentTarget.value)
                }
              />
            </FormControl>
          </Box>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Email</FormLabel>
            <Input
              borderColor="gray.200"
              type="email"
              placeholder="Email"
              onChange={({ currentTarget }) => setEmail(currentTarget.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize="sm">Phone Number</FormLabel>
            <Input
              borderColor="gray.200"
              type="tel"
              placeholder="Phone Number"
              onChange={({ currentTarget }) =>
                setPhoneNumber(currentTarget.value)
              }
              inputMode="numeric"
              value={formatPhoneNumber(phoneNumber)}
            />
          </FormControl>

          <FormControl isRequired isInvalid={hasBirthdayError}>
            <FormLabel fontSize="sm">Birthday</FormLabel>
            <Input
              type="date"
              onChange={({ currentTarget }) =>
                setBirthdate(currentTarget.value)
              }
            />
            <FormErrorMessage>
              You must be 21 years or older to sign up for this club.
            </FormErrorMessage>
          </FormControl>

          {hasTShirts && (
            <FormControl isRequired>
              <FormLabel fontSize="sm">T-Shirt Size</FormLabel>
              <Select
                placeholder="Select Size"
                onChange={({ currentTarget }) =>
                  setShirtSize(currentTarget.value)
                }
              >
                <option value="x-small">X-Small</option>
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
                <option value="x-large">X-Large</option>
                <option value="xx-large">XX-Large</option>
                <option value="xxx-large">XXX-Large</option>
              </Select>
            </FormControl>
          )}

          {hasPatronMugNumberSelection && (
            <MugNumberGrid
              clubId={clubId}
              onChange={setMugNumber}
              value={mugNumber}
            />
          )}

          {customQuestions && (
            <FormControl>
              <FormLabel fontSize="sm">{customQuestions}</FormLabel>

              <Textarea
                placeholder="Enter your answer here."
                onChange={({ currentTarget }) => setNotes(currentTarget.value)}
                value={notes}
              />
            </FormControl>
          )}

          <SmsCheckbox isChecked={canReceiveSms} onChange={setCanReceiveSms} />
        </VStack>

        <Button type="submit" w="100%" isLoading={isLoading} mb={4}>
          Sign Up
        </Button>

        <Terms terms={club.terms} />
      </form>
    </Box>
  );
}

export default memo(PatronSignUpForm);
