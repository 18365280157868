import { memo } from 'react';
import { Box, Heading, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { IFetchClubSignupData } from '../../../api';
import { CheckCircle } from '../../../assets';
import { IClubReward } from '../../../types';

interface Props {
  pageData: IFetchClubSignupData;
}

function ClubDetails({ pageData }: Props): JSX.Element {
  const { club } = pageData;

  return (
    <>
      <Box mb={6}>
        <Heading as="h3" fontSize="md" fontWeight="semibold">
          Club Duration
        </Heading>
        <Text color="gray.500" fontSize="sm" mb={2}>
          How long the membership lasts.
        </Text>

        <Text fontSize="sm">
          {formatClubDuration(club.endDate, club.membershipDurationMonths)}
        </Text>
      </Box>

      {club.registrationEndDate && (
        <Box mb={6}>
          <Heading as="h3" fontSize="md" fontWeight="semibold">
            Registration Deadline
          </Heading>
          <Text color="gray.500" fontSize="sm" mb={2}>
            The date at which registration for this club closes.
          </Text>

          <Text fontSize="sm">
            {dayjs(club.registrationEndDate).format('MM/DD/YYYY')}
          </Text>
        </Box>
      )}

      {club.benefits.length > 0 && (
        <Box mb={6}>
          <Heading as="h3" fontSize="md" fontWeight="semibold">
            Club Benefits
          </Heading>
          <Text color="gray.500" fontSize="sm" mb={2}>
            The perks that come with the membership.
          </Text>

          <List spacing={1}>
            {club.benefits.map((benefit) => (
              <ListItem
                key={benefit}
                display="flex"
                alignItems="center"
                fontSize="sm"
              >
                <ListIcon as={CheckCircle} color="green.500" />
                <Text>{benefit}</Text>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {club.rewards.length > 0 && (
        <Box mb={6}>
          <Heading as="h3" fontSize="md" fontWeight="semibold">
            Club Rewards
          </Heading>
          <Text color="gray.500" fontSize="sm" mb={2}>
            Redeemable when certain conditions are met.
          </Text>

          <List spacing={1}>
            {club.rewards.map((reward) => (
              <ListItem
                key={reward.description}
                display="flex"
                alignItems="center"
                fontSize="sm"
              >
                <ListIcon as={CheckCircle} color="green.500" />
                <Text>{formatRewardDescription(reward)}</Text>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </>
  );
}

function formatRewardDescription(reward: IClubReward): string {
  if (reward.threshold === 0) {
    return reward.description;
  }

  return `${reward.description} at ${reward.threshold} visits`;
}

function formatClubDuration(
  endDate?: string | null,
  membershipDurationMonths?: number | null,
): string {
  if (membershipDurationMonths) {
    return `Membership lasts ${membershipDurationMonths} month${
      membershipDurationMonths > 1 ? 's' : ''
    } from the date of activation.`;
  }

  const formattedEndDate = dayjs(endDate).format('MM/DD/YYYY');

  return `Membership lasts from the date of activation through ${formattedEndDate}.`;
}

export default memo(ClubDetails);
