import { formatGeneral } from 'cleave-zen';

export function formatPhoneNumber(value?: string): string | undefined {
  if (!value) {
    return;
  }

  return formatGeneral(value, {
    blocks: [0, 3, 3, 4],
    delimiters: ['(', ') ', '-'],
    delimiterLazyShow: true,
    numericOnly: true,
  });
}

export function unformatPhoneNumber(value?: string): string {
  if (!value) {
    return '';
  }

  return value.replace(/\D/g, '');
}
