import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  EmptyState,
  PatronName,
  SortableTableHeader,
} from '../../../components';
import PatronsTableMembershipStatus from './PatronsTableMembershipStatus';
import { IClub, IRecord } from '../../../types';
import { useFetchPatrons } from '../../../hooks';
import {
  filterActivePatrons,
  mapPatronsToRecords,
  orderPatronsByRecordCount,
} from './utils';
import { formatMembershipExpirationDate } from '../../../utils';

interface IParams {
  breweryId: string;
  club: IClub;
  emptyStateButtonText?: string;
  emptyStateOnClick?: () => {};
  records: IRecord[];
}

export default function PatronsTable({
  breweryId,
  club,
  emptyStateButtonText,
  emptyStateOnClick,
  records,
}: IParams): JSX.Element {
  const [orderedDescending, setOrderedDescending] = useState<boolean>(true);
  const [patrons, isLoading] = useFetchPatrons(breweryId);
  const navigate = useNavigate();
  const [patronsInClub] = useMemo(
    () => filterActivePatrons(patrons, club.id),
    [club.id, patrons],
  );
  const patronsInClubWithRecords = useMemo(
    () => mapPatronsToRecords(patronsInClub, records),
    [patronsInClub, records],
  );
  const patronsOrderedByRecordCount = useMemo(
    () =>
      orderPatronsByRecordCount(patronsInClubWithRecords, orderedDescending),
    [patronsInClubWithRecords, orderedDescending],
  );
  const clubHasRewards = Boolean(club?.rewards.length);
  const clubHasRollingMemberships = Boolean(club.membershipDurationMonths);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  function handleOnChangeOrder() {
    setOrderedDescending(!orderedDescending);
  }

  function handleClick(id: string) {
    navigate(`/auth/patrons/${id}`);
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Heading as="h2" fontSize="lg">
          Patrons ({patronsOrderedByRecordCount.length} total)
        </Heading>
      </Box>

      {patronsOrderedByRecordCount?.length ? (
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Mug Number</Th>
              <Th>Status</Th>
              {clubHasRewards && (
                <>
                  <SortableTableHeader
                    onClick={handleOnChangeOrder}
                    active
                    orderedDescending={orderedDescending}
                  >
                    Records
                  </SortableTableHeader>
                  <Th>Last Active</Th>
                </>
              )}
              {clubHasRollingMemberships && <Th>Membership Expires</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {patronsOrderedByRecordCount.map((patron) => (
              <Tr
                key={patron.id}
                onClick={() => handleClick(patron.id)}
                cursor="pointer"
                _hover={{
                  backgroundColor: 'gray.50',
                }}
              >
                <Td>
                  <PatronName patron={patron} />
                </Td>
                <Td>{patron.mugNumber ? patron.mugNumber : '-'}</Td>
                <Td>
                  <PatronsTableMembershipStatus club={club} patron={patron} />
                </Td>
                {clubHasRewards && (
                  <>
                    <Td>{patron.records.length}</Td>
                    <Td>
                      {patron.lastActive?.toDate().toLocaleDateString() ||
                        'N/A'}
                    </Td>
                  </>
                )}
                {clubHasRollingMemberships && (
                  <Td>
                    {formatMembershipExpirationDate(patron.membershipExpiresAt)}
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <EmptyState
          buttonText={emptyStateButtonText}
          onClick={emptyStateOnClick}
        />
      )}
    </>
  );
}
