import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { IPatron } from '../../types';

type CreatePatronParams = Omit<IPatron, 'id'>;

export async function createPatron(
  params: CreatePatronParams,
): Promise<IPatron> {
  const patronsCollection = collection(db, 'patrons');

  const patronDoc = await addDoc(patronsCollection, params);

  return {
    ...params,
    id: patronDoc.id,
  };
}

type UpdatePatronParams = Partial<
  Pick<
    IPatron,
    | 'birthdayMonth'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'mugNumber'
    | 'phoneNumber'
    | 'shirtSize'
  >
>;

export async function updatePatron(
  patronId: string,
  params: UpdatePatronParams,
): Promise<void> {
  if (!patronId) {
    return;
  }

  const patronRef = doc(db, `patrons/${patronId}`);

  await updateDoc(patronRef, params);
}

export async function updatePatronNotes(
  patronId: string,
  {
    breweryId,
    notes,
  }: {
    breweryId: string;
    notes?: string;
  },
): Promise<void> {
  if (!patronId || !breweryId) {
    return;
  }

  const patronRef = doc(db, `patrons/${patronId}`);

  await updateDoc(patronRef, {
    [`notes.${breweryId}`]: notes || null,
  });
}
