import { VStack } from '@chakra-ui/react';
import RenewalReminders from './RenewalReminders';
import MugNumbers from './MugNumbers';
import ShirtSwitch from './ShirtSwitch';
import { Drawer } from '../../../../components';
import { IClub } from '../../../../types';

interface IProps {
  club?: IClub;
  isOpen: boolean;
  onClose: () => void;
}

export default function ClubSettingsForm({
  club,
  isOpen,
  onClose,
}: IProps): JSX.Element {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      showFooter={false}
      size="md"
      title="Change Club Settings"
    >
      {club && (
        <VStack spacing={4}>
          <RenewalReminders club={club} />
          <MugNumbers club={club} />
          <ShirtSwitch club={club} />
        </VStack>
      )}
    </Drawer>
  );
}
