import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { ClubStatus, IClub } from '../../types';

type UpdateClubParams = Pick<IClub, 'hasTShirts'>;

export async function updateClub(
  clubId: string,
  params: UpdateClubParams,
): Promise<void> {
  if (!clubId || !params) {
    return;
  }

  const clubRef = doc(db, `clubs/${clubId}`);

  await updateDoc(clubRef, {
    ...params,
  });
}

export async function updateClubStatus(
  clubId: string,
  status: ClubStatus,
): Promise<void> {
  if (!clubId || !status) {
    return;
  }

  const clubRef = doc(db, `clubs/${clubId}`);

  await updateDoc(clubRef, {
    status,
  });
}

type UpdateClubConfigParams = Partial<
  Pick<
    IClub,
    'hasMugNumbers' | 'hasPatronMugNumberSelection' | 'hasRenewalReminders'
  >
>;

export async function updateClubConfig(
  clubId: string,
  config: UpdateClubConfigParams,
): Promise<void> {
  if (!clubId || !config) {
    return;
  }

  const clubRef = doc(db, `clubs/${clubId}`);

  await updateDoc(clubRef, {
    ...config,
  });
}
