import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { updateClub } from '../../../../../api';
import { HelpCircle } from '../../../../../assets';
import { IClub } from '../../../../../types';

interface Props {
  club: IClub;
}

export default function ShirtSwitch({ club }: Props): JSX.Element {
  const toast = useToast();

  async function handleChangeRenewalReminders(checked: boolean) {
    if (!club) {
      return;
    }

    try {
      await updateClub(club.id, {
        hasTShirts: checked,
      });

      toast({
        description: 'Club t-shirt setting updated.',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred updating the club t-shirt setting. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return (
    <>
      <FormControl>
        <FormLabel alignItems="center" display="flex" gap={2} mb={1}>
          Free Club T-Shirt
          <Tooltip
            hasArrow
            label="When this option is enabled, members will
          select their t-shirt size when signing up for your club."
          >
            <Box as="span" mt="-3px">
              <HelpCircle color="gray.500" />
            </Box>
          </Tooltip>
        </FormLabel>
        <FormHelperText mt={0} mb={2}>
          Enable if your club offers a free t-shirt to members.
        </FormHelperText>

        <Switch
          defaultChecked={club?.hasTShirts}
          colorScheme="green"
          onChange={({ currentTarget }) =>
            handleChangeRenewalReminders(currentTarget.checked)
          }
        />
      </FormControl>
    </>
  );
}
