import { memo } from 'react';
import { Box, Heading, useToast } from '@chakra-ui/react';
import { updateBrewery } from '../../api';
import { BreweryForm } from '../../components';
import { IBrewery } from '../../types';

type SubmitValues = {
  name: string;
  website: string;
  phoneNumber: string;
};

interface Props {
  brewery: IBrewery;
}

function BreweryTab({ brewery }: Props): JSX.Element {
  const toast = useToast();

  async function handleSubmit(values: SubmitValues) {
    try {
      await updateBrewery(brewery.id, values);

      toast({
        description: 'Brewery details updated',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred updating brewery details. Please try again.',
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return (
    <Box maxWidth={580}>
      <Heading as="h2" fontSize="lg" mb={4}>
        Brewery Details
      </Heading>

      <BreweryForm
        brewery={brewery}
        submitButtonText="Update Brewery"
        onSubmit={handleSubmit}
      />
    </Box>
  );
}

export default memo(BreweryTab);
