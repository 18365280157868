import { FormHelperText, Input } from '@chakra-ui/react';

interface Props {
  onChange: (value: string) => void;
  value?: string;
}

export default function MugNumberInput({
  onChange,
  value,
}: Props): JSX.Element {
  return (
    <>
      <FormHelperText mt={1} mb={2}>
        Optionally add a mug number.
      </FormHelperText>
      <Input
        id="mugNumber"
        type="number"
        placeholder="Mug Number"
        onChange={({ target }) => onChange(target.value)}
        defaultValue={value}
      />
    </>
  );
}
