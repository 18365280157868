import { memo } from 'react';
import { Heading, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import Detail from './Detail';
import SmsOptIn from './SmsOptIn';
import { formatPhoneNumber as formatPhoneNumberMask } from '../../../utils';
import { IPatron } from '../../../types';

interface Props {
  patron: IPatron;
}

function PatronDetails({ patron }: Props): JSX.Element {
  const {
    birthdayMonth,
    canReceiveSms,
    createdAt,
    email,
    lastUpdatedAt,
    phoneNumber,
    shirtSize,
  } = patron;

  const formattedBirthdayMonth = formatBirthdayMonth(birthdayMonth);
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  return (
    <VStack
      spacing={1}
      alignItems="flex-start"
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
      shadow="sm"
      py={2}
      px={3}
    >
      <Heading as="h2" size="sm" mb={2}>
        Patron Details
      </Heading>

      <Detail label="Email" value={email ? email : 'Not provided'} />

      <Detail label="Phone Number">
        <Text fontSize="sm" display="flex" alignItems="center" gap={1}>
          {canReceiveSms && <SmsOptIn />}
          {formattedPhoneNumber}
        </Text>
      </Detail>

      <Detail label="Birthday Month" value={formattedBirthdayMonth} />

      <Detail
        label="Shirt Size"
        value={shirtSize ? shirtSize.toUpperCase() : 'Not provided'}
      />

      <Detail
        label="Last Updated"
        value={
          lastUpdatedAt
            ? dayjs(lastUpdatedAt.toDate()).format('MM/DD/YYYY')
            : dayjs(createdAt.toDate()).format('MM/DD/YYYY')
        }
      />
    </VStack>
  );
}

function formatPhoneNumber(phoneNumber?: string | null): string | undefined {
  if (!phoneNumber) {
    return 'Not provided';
  }

  const cleaned = phoneNumber.replace(/[.\-\s]/g, '');

  return formatPhoneNumberMask(cleaned);
}

function formatBirthdayMonth(birthdayMonth?: string | null): string {
  if (!birthdayMonth) {
    return 'Not provided';
  }

  return `${birthdayMonth.charAt(0).toUpperCase()}${birthdayMonth.slice(1)}`;
}

export default memo(PatronDetails);
