import { useCallback, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { IBrewery } from '../types';

export default function useFetchBreweryById(
  id?: string,
): [IBrewery | null, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [brewery, setBrewery] = useState<IBrewery | null>(null);

  const fetchBrewery = useCallback(async () => {
    if (!id) {
      return;
    }

    try {
      const breweryRef = doc(db, `breweries/${id}`);
      const breweryDoc = await getDoc(breweryRef);

      setBrewery({
        id: breweryDoc.id,
        ...breweryDoc.data(),
      } as IBrewery);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchBrewery();
    }
  }, [id, fetchBrewery]);

  return [brewery, isLoading];
}
