import { signOut } from 'firebase/auth';
import { NavLink as ReactRouterLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { captureException, setUser } from '@sentry/react';
import posthog from 'posthog-js';
import HelpModal from './HelpModal';
import NavigationItem from './NavigationItem';
import { LogoPatch, InfoCircleIcon, LogOut, Settings } from '../../assets';
import { auth } from '../../firebase';

const Navigation = (): JSX.Element => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function handleClick() {
    try {
      await signOut(auth);
      posthog.reset();
      setUser(null);
      navigate('/');
    } catch (error) {
      captureException(error);
    }
  }

  return (
    <>
      <HelpModal isOpen={isOpen} onClose={onClose} />

      <Box
        display="flex"
        flexDirection="column"
        py={4}
        px={3}
        borderRight="1px"
        borderColor="gray.200"
        as="header"
        justifyContent="center"
        alignItems="center"
        w="75px"
      >
        <Box display="flex" fontSize="2xl" mb={8}>
          <Text>
            <LogoPatch />
          </Text>
        </Box>

        <Box display="flex" flexDirection="column" gridGap={3}>
          <NavigationItem page="clubs" />
          <NavigationItem page="patrons" />
          <NavigationItem page="messages" />
          <NavigationItem page="mugs" />
        </Box>

        <Box mt="auto" mx="auto">
          <Menu>
            <MenuButton>
              <Avatar size="xs" bg="gray.400" />
            </MenuButton>

            <MenuList>
              <Link to="/auth/settings" as={ReactRouterLink} width="100%">
                <MenuItem display="flex" alignItems="center" gap={2}>
                  <Settings /> Settings
                </MenuItem>
              </Link>

              <MenuItem
                alignItems="center"
                color="green.500"
                display="flex"
                gap={2}
                onClick={onOpen}
              >
                <InfoCircleIcon /> Help
              </MenuItem>

              <MenuDivider />

              <MenuItem
                onClick={handleClick}
                display="flex"
                alignItems="center"
                gap={2}
                color="green.500"
                mt={2}
              >
                <LogOut />
                <Link variant="outline">Log Out</Link>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </>
  );
};

export default Navigation;
