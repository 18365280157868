import { Box, Button, FormHelperText, Select } from '@chakra-ui/react';
import { useGetAvailableMugsByClub } from '../../../hooks';
import { IPatron } from '../../../types';

interface Props {
  clubId: string;
  onChange: (value: string) => void;
  patronId?: string;
  size?: string;
  value?: string;
}

export default function MugNumberSelect({
  clubId,
  onChange,
  patronId,
  size = 'md',
  value,
}: Props): JSX.Element {
  const [mugs, isLoading] = useGetAvailableMugsByClub(clubId);

  function handleChange({
    currentTarget,
  }: React.ChangeEvent<HTMLSelectElement>): void {
    onChange(currentTarget.value);
  }

  return (
    <>
      <FormHelperText mt={0} mb={2}>
        Select a mug number from a list of available mugs.
      </FormHelperText>

      <Box display="flex" alignItems="center" gap={4}>
        <Select
          onChange={handleChange}
          value={value || 'default'}
          isDisabled={isLoading}
          size={size}
          rounded="md"
        >
          <option value="default" disabled>
            {isLoading ? 'Loading...' : 'Select Mug Number'}
          </option>
          {mugs.map(({ number, patron }) => (
            <SelectOption
              key={number}
              number={number}
              patron={patron}
              patronId={patronId}
            />
          ))}
        </Select>

        {patronId && (
          <Button size="sm" variant="ghost" onClick={() => onChange('default')}>
            Clear Mug
          </Button>
        )}
      </Box>
    </>
  );
}

interface SelectOptionProps {
  number: number;
  patron?: IPatron;
  patronId?: string;
}

function SelectOption({
  number,
  patron,
  patronId,
}: SelectOptionProps): JSX.Element {
  const isAssignedToPatron = Boolean(patron) && patronId === patron?.id;
  const isDisabled = !isAssignedToPatron && Boolean(patron);
  let text = Boolean(patron) ? 'Not Available' : 'Available';

  if (isAssignedToPatron) {
    text = 'Current Mug Assignment';
  }

  return (
    <option key={number} value={number} disabled={isDisabled}>
      {number} - {text}
    </option>
  );
}
