import { Link as ReactRouterLink } from 'react-router-dom';
import { Box, Link, Text } from '@chakra-ui/react';
import { AlertCircle, LogoPatch } from '../../assets';

export default function Success(): JSX.Element {
  return (
    <Box
      bg="gray.100"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box bg="white" textAlign="center" rounded="lg" shadow="md" p={6} w={384}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          flexDir="column"
          gap={2}
          mb={6}
        >
          <LogoPatch height="48" width="48" />
          <Text fontSize="xl" fontWeight="semibold">
            Invalid Password Reset Code
          </Text>
        </Box>

        <AlertCircle fontSize="4xl" mb={4} color="orange.500" />

        <Text mb={8} color="gray.500">
          The code used to verify your identify is no longer valid. To request a
          new password reset code,{' '}
          <Link as={ReactRouterLink} to="/request-password-reset">
            click here
          </Link>
          .
        </Text>

        <Text color="gray.500" mb={8}>
          Need help? Email us directly at{' '}
          <Link href="mailto:help@mugclub.beer">help@mugclub.beer</Link>.
        </Text>

        <Text color="gray.500">
          <Link as={ReactRouterLink} to="/">
            Click here
          </Link>{' '}
          to return to the Login page.
        </Text>
      </Box>
    </Box>
  );
}
