import { useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { captureException } from '@sentry/react';
import { auth } from '../../../../firebase';
import { ArrowRightIcon } from '../../../../assets';

interface IProps {
  onSubmit: () => void;
}

function getErrorMessageFromCode(errorCode?: string): string {
  if (errorCode === 'auth/email-already-in-use') {
    return 'An account with this email already exists.';
  }

  return 'An unknown error occurred while creating your account.';
}

export default function AccountSetup({ onSubmit }: IProps): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const toast = useToast();

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setErrorMessage(null);

    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters long.');
      return;
    }

    setIsLoading(true);

    const formattedEmail = email.trim().toLowerCase();

    try {
      await createUserWithEmailAndPassword(auth, formattedEmail, password);

      onSubmit();
    } catch (error: any) {
      const errorMessage = getErrorMessageFromCode(error?.code);

      captureException(error);

      setErrorMessage(errorMessage);

      toast({
        isClosable: true,
        description: errorMessage,
        position: 'top-right',
        status: 'error',
        title: 'Uh Oh.',
        variant: 'left-accent',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <FormControl isRequired mb={4}>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Input
          placeholder="Email"
          type="email"
          onChange={({ currentTarget }) => setEmail(currentTarget.value)}
        />
      </FormControl>

      <FormControl isRequired mb={6} isInvalid={Boolean(errorMessage)}>
        <FormLabel htmlFor="password">Password</FormLabel>
        <Input
          placeholder="Password"
          type="password"
          onChange={({ currentTarget }) => setPassword(currentTarget.value)}
        />
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>

      <Button
        isLoading={isLoading}
        rightIcon={<ArrowRightIcon />}
        w="100%"
        type="submit"
      >
        Next
      </Button>

      <Text color="gray.500" fontSize="sm" mt={2} textAlign="center">
        By clicking "Next" you agree to MugClub.beer's{' '}
        <Link target="_blank" href="https://mugclub.beer/terms-of-service">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link target="_blank" href="https://mugclub.beer/privacy-policy">
          Privacy Policy
        </Link>
        .
      </Text>

      <Text mt={6} textAlign="center" color="gray.500" fontSize="sm">
        Already have an account?{' '}
        <Link as={ReactRouterLink} to="/">
          Click here
        </Link>{' '}
        to return to the Login page.
      </Text>
    </Box>
  );
}
