import { memo } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { IFetchClubSignupData } from '../../../api';

interface Props {
  pageData: IFetchClubSignupData;
}

function ClubOverview({ pageData }: Props): JSX.Element {
  const { brewery, club } = pageData;

  return (
    <Box
      py={6}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Heading as="h1" fontSize="xl" fontWeight="bold">
          {brewery.name}
        </Heading>

        <Heading as="h2" fontSize="md" color="gray.500" fontWeight="normal">
          {club.name}
        </Heading>
      </Box>

      {club.cost && (
        <Box
          fontSize="xl"
          fontWeight="medium"
          bg="green.300"
          color="white"
          px={2}
          borderRadius={4}
        >
          ${club.cost}
        </Box>
      )}
    </Box>
  );
}

export default memo(ClubOverview);
