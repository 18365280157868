import { Box, Divider } from '@chakra-ui/react';
import { MessageTextArea, ReadOnlyMessageTextArea } from '../components';
import {
  BreweryConfigOptions,
  IBrewery,
  IBreweryConfig,
  ProductPlan,
} from '../../../types';

interface IProps {
  brewery: IBrewery;
  breweryConfig: IBreweryConfig;
}

function formatPatronSignUpEmail(brewery: IBrewery): string {
  const { name } = brewery;

  return `👋 Hello {{PATRON_FIRST_NAME}},\n
Welcome! This email confirms your registration for ${name}'s mug club. If you haven't already, please see a member of brewery staff to activate your membership.\n
If you have any questions, please ask a member of brewery staff for assistance.\n
Cheers,\n
The team at ${name}
  `;
}

export default function ClubSignUp({
  breweryConfig,
  brewery,
}: IProps): JSX.Element {
  const { patronSignedUpForClubEmailEnabled } = breweryConfig;
  const { plan } = brewery;
  const hasFreePlan = plan === ProductPlan.FREE;

  return (
    <Box>
      <MessageTextArea
        breweryId={brewery.id}
        configOption={BreweryConfigOptions.clubSignUpInstructions}
        defaultValue={breweryConfig.clubSignUpInstructions}
        description="Displayed at the top of the club sign up form. Use this message to provide any instructions that patrons should know before signing up for your club."
        isFeatureGated={hasFreePlan}
        label="Sign Up Instructions"
        messageType="web"
      />

      <Divider my={8} />

      <MessageTextArea
        breweryId={brewery.id}
        configOption={BreweryConfigOptions.clubSignUpConfirmation}
        defaultValue={breweryConfig.clubSignUpConfirmation}
        description="Displayed after a patron signs up for a club. Use this message to provide next steps or additional information you would like new members to know."
        isFeatureGated={hasFreePlan}
        label="Sign Up Confirmation"
        messageType="web"
      />

      <Divider my={8} />

      <ReadOnlyMessageTextArea
        breweryId={brewery.id}
        configOption={BreweryConfigOptions.patronSignedUpForClubEmailEnabled}
        defaultValue={formatPatronSignUpEmail(brewery)}
        isDefaultEnabled={patronSignedUpForClubEmailEnabled}
        description="Email sent to patrons after they sign up for a club."
        isToggleable={true}
        label="Sign Up Email"
        messageType="email"
        rows={12}
      />
    </Box>
  );
}
