import { Link as ReactRouterLink } from 'react-router-dom';
import { Box, Heading, Link, Text } from '@chakra-ui/react';
import { CheckCircle } from '../../assets';

export default function Success(): JSX.Element {
  return (
    <Box
      bg="gray.100"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box bg="white" textAlign="center" rounded="lg" shadow="md" p={6} w={384}>
        <CheckCircle fontSize="4xl" mb={4} color="green.500" />

        <Heading size="lg" as="h1" mb={8}>
          Password Reset Successful
        </Heading>

        <Text color="gray.500">
          <Link as={ReactRouterLink} to="/">
            Click here
          </Link>{' '}
          to return to the Login page and login with your new password.
        </Text>
      </Box>
    </Box>
  );
}
