import { memo } from 'react';
import { Box, Text } from '@chakra-ui/react';

interface Props {
  message: string;
}

function InfoMessage({ message }: Props): JSX.Element {
  return (
    <Box
      p={4}
      border="2px solid"
      borderColor="green.600"
      bg="green.50"
      rounded="md"
      mb={6}
    >
      <Text fontWeight="semibold" color="green.600" mb={1}>
        Welcome
      </Text>

      <Text whiteSpace="pre-line" fontSize="sm">
        {message}
      </Text>
    </Box>
  );
}

export default memo(InfoMessage);
