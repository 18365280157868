import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MembershipStatusBadge } from '../../../components';
import { useFetchPatronMembershipByClub } from '../../../hooks';
import { IClub, IPatron } from '../../../types';

interface Props {
  club: IClub;
  patron: IPatron;
}

function MembershipStatus({ club, patron }: Props): JSX.Element {
  const memberSince = useMemo(() => {
    return dayjs(patron.createdAt.toDate()).format('MM/DD/YYYY');
  }, [patron]);
  const { hasMugNumbers, id, name, membershipDurationMonths } = club;
  const [membership] = useFetchPatronMembershipByClub(patron.id, id, true);

  return (
    <Box>
      <Box
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        shadow="sm"
        py={2}
        px={3}
      >
        <Heading as="h2" size="sm" mb={2}>
          Membership
        </Heading>

        <Box display="grid" gap={1}>
          <Box>
            <Text color="gray.600" fontSize="2xs" fontWeight="semibold">
              Club Name
            </Text>
            <Text
              as={Link}
              color="green.500"
              fontSize="sm"
              to={`/auth/clubs/${id}`}
            >
              {name}
            </Text>
          </Box>

          <Box>
            <Text color="gray.600" fontSize="2xs" fontWeight="semibold">
              Membership Status
            </Text>
            {membership && <MembershipStatusBadge membership={membership} />}
          </Box>

          {membership && (
            <Box>
              <Text color="gray.600" fontSize="2xs" fontWeight="semibold">
                Membership Expiration Date
              </Text>
              <Text fontSize="sm">
                {membership.expiresAt
                  ? dayjs(membership.expiresAt?.toDate()).format('MM/DD/YYYY')
                  : 'N/A'}
              </Text>
            </Box>
          )}

          {hasMugNumbers && (
            <Box>
              <Text color="gray.600" fontSize="2xs" fontWeight="semibold">
                Mug Number
              </Text>
              <Text fontSize="sm">{patron.mugNumber || 'Not provided'}</Text>
            </Box>
          )}

          {membershipDurationMonths && (
            <Box>
              <Text color="gray.600" fontSize="2xs" fontWeight="semibold">
                Club Membership Duration
              </Text>
              <Text fontSize="sm">{membershipDurationMonths} months</Text>
            </Box>
          )}

          <Box>
            <Text color="gray.600" fontSize="2xs" fontWeight="semibold">
              Member Since
            </Text>
            <Text fontSize="sm">{memberSince}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(MembershipStatus);
